<template>
  <!-- 页面名称：我的错题
  开发人员：zqq-->
  <div class="mistakes-content">
    <div class="adv-img">
      <!-- <img src="../../../assets/img/wrongTopic.png" alt="错题" /> -->
      <div class="adv-txt">
        <div class="adv-system">
          <span class="system">我的错题</span>
          <span class="highOrder">高阶人生提升学历</span>
        </div>
        <p class="optimistic">
          要学会乐观的学习。子曰：“知之者不如好之者，好之者不如乐之者”，此
        </p>
      </div>
    </div>
    <div class="loading" v-loading="loading">
      <div class="warp">
        <div class="title">
          <span
            v-for="(item, index) in titleList"
            :key="index"
            @click="changeTitle(index)"
            :class="{ span_hover: index === titleIndex }"
            >{{ item }}</span
          >
        </div>
        <div class="list-topic">
          <div
            class="list"
            @click="chapter(items)"
            v-for="(items, topiIndex) in topicList[titleIndex]"
            :key="topiIndex"
          >
            <div class="list-subject">
              <p class="subject">{{ items.crs_name }}</p>
              <p class="list-topic1">
                <span class="list-num">{{ items.total }}</span>
                <span class="topic">题</span>
              </p>
            </div>
            <div class="list-img"></div>
          </div>
        </div>
        <div v-if="topicList[titleIndex] == false">
          <defaultPage :text="text"></defaultPage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "../../../http/api/WrongTopic";
import defaultPage from "../data404.vue";
export default {
  components: {
    defaultPage,
  },
  created() {
    //生命周期-组件创建（数据和方法都已经被创建）
  },
  props: {
    //接收父组件的传值    props: ['fatherData'], ||  props: {fatherData:data,}
  },
  mounted() {
    this.getList();
  },
  data() {
    //数据

    return {
      titleList: ["章节练习", "历年真题", "模拟试题"],
      titleIndex: 0,
      activeName: 0,

      topicList: [],

      loading: true,

      text: "抱歉 , 您占时没有内容~~",
    };
  },

  methods: {
    getList() {
      getList().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          // console.log(res.data);
          this.topicList = res.data;
        } else {
          this.$message.error(this.error);
        }
        this.loading = false;
      });
    },
    //方法
    changeTitle(index) {
      this.titleIndex = index;
    },
    chapter(item) {
      // console.log(item);
      var items = JSON.stringify(item);

      this.$router.push({
        path: `wrongTopic/chapterOptions?titleIndex=${this.titleIndex}&items=${items}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mistakes-content {
  min-height: 880px;
  padding-bottom: 50px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  .adv-img {
    width: 100%;
    height: 160px;
    background: url("../../../assets/img/wrongTopic.png");
    background-size: cover;
    // img {
    //   width: 100%;
    //   height: 164px;
    // }
    .adv-txt {
      position: absolute;
      top: 103px;
      left: 37%;
      .adv-system {
        .system {
          font-size: 27px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: center;
          color: #ffe164;
          margin-right: 31px;
        }
        .highOrder {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
        }
      }

      .optimistic {
        margin-top: 8px;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.7;
      }
    }
  }
  .loading {
    width: 100%;
    min-height: 100px;
    .warp {
      width: 67%;
      margin: 0 auto;
      .title {
        height: 65px;
        display: flex;
        span {
          font-family: PingFang SC;
          line-height: 25px;
          font-size: 18px;
          font-weight: 400;
          color: #657489;
          padding: 20px 0;
          cursor: pointer;
        }
        span:nth-child(2) {
          margin: 0 40px;
        }
        .span_hover {
          position: relative;
          color: #00264d;
          font-weight: 500;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 3px;
            border-radius: 1.5px;
            background-color: #3d89fb;
          }
        }
      }
      .list-topic {
        display: flex;
        padding-top: 20px;
        flex-wrap: wrap;
        justify-content: space-between;
        /* 折中 */
        @media screen and (min-width: 1025px) and (max-width: 1281px) {
          .list {
            width: 100%;
          }
        }

        /* 折中 */
        @media screen and (min-width: 1281px) and (max-width: 1679px) {
          .list {
            width: 48%;
          }
        }
      }

      .list {
        width: calc((100% - 20px * 3) / 3);
        height: 120px;
        opacity: 1;
        cursor: pointer;
        background: #ffffff;
        border-radius: 6px;
        display: flex;
        margin-bottom: 20px;
        margin-right: 20px;
        justify-content: space-between;
        align-items: center;
        .list-subject {
          margin-left: 32px;
          .subject {
            width: 272px;
            height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 600;
            color: #00264d;
            padding: 0;
            margin-top: 24px;
            margin-bottom: 16px;
          }
          .list-topic1 {
            display: flex;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: left;
            color: #00264d;
            align-items: center;
            .list-num {
              font-size: 24px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 600;
              color: #fc6257;
              margin-right: 5px;
            }
            .topic {
              font-size: 14px;
              margin-left: 5px;
            }
          }
        }
        .list-img {
          margin-right: 32px;
          width: 32px;
          height: 32px;
          background: url("../../../assets/img/look.png");
          background-size: 32px;
          // margin-top: 44px;
        }
      }
      .list:hover {
        .list-img {
          transition: 0.5s;
          background: url("../../../assets/img/look-active.png");
          background-size: 32px;
        }
      }
      .list-topic:after {
        content: "";
        flex: auto;
      }
    }
  }
}
/* 移动端 */
@media screen and (max-width: 1024px) {
  .mistakes-content {
    padding-bottom: 0;
    box-sizing: border-box;
    background-color: #f5f5f5;

    .adv-img {
      width: 100%;
      height: 160px;
      background: url("../../../assets/img/wrongTopic.png");
      background-size: cover;

      .adv-txt {
        position: absolute;
        top: 75px;
        left: 0;

        .adv-system {
          padding: 0 10px;
          .system {
            font-size: 24px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            color: #ffe164;
            margin-right: 31px;
          }

          .highOrder {
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .optimistic {
          margin: 8px 10px 0 10px;
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.7;
        }
      }
    }
    .loading{
      .warp {
        width: 100%;
        max-width: 100%;
        min-height: 100px;
        .title{
          margin-left: 20px;
        }
        .list-topic {
          .list{
            width: 100%;
            /*width: calc(100% - 40px);*/
            margin: 0 20px 20px 20px;
            .list-subject{
              margin: 0;
              width: 250px;
              .subject{
                margin-left: 20px;
              }
              .list-topic1{
                margin-left: 20px;
              }
            }
            .list-img{
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
